<template>
  <div>
    <section class="py-3 px-5 page-banner" v-if="userDetails">
      <router-link to="/users" class="go-back-link"
        ><BIconArrowLeftShort />Back to users</router-link
      >
      <h3 class="page-header py-3">
        {{
          userDetails.portalUser.firstName +
          " " +
          userDetails.portalUser.lastName
        }}
      </h3>
      <b-row class="justify-content-between">
        <b-col cols="12" class="col-sm-6">
          <b-nav role="navigation">
            <b-nav-item href="#" active>Details</b-nav-item>
          </b-nav>
        </b-col>
        <b-col cols="12" class="col-sm-6 text-right mt-n4">
          <b-button
            type="button"
            v-activeBlur
            :disabled="restrictUsersToMultipleCity || validateUserToSelectedTenant"
            @click="activateUserConfirmModal = true"
            class="transparent mr-3"
            >{{
              userDetails.portalUser.isActive ? `Deactivate` : `Activate`
            }}</b-button
          >
          <b-button
            type="button"
            v-activeBlur
            :disabled="restrictUsersToMultipleCity || validateUserToSelectedTenant"
            class="transparent mr-3"
            @click="resetPasswordConfirmModal = true"
            >Reset password</b-button
          >
        </b-col>
      </b-row>
    </section>
    <LoaderIcon v-if="userDetailsLoading" />
    <section class="px-5 mt-4" v-else-if="restrictUsersToMultipleCity">
      <b-form-row>
        <b-col>
          <b-alert show variant="danger">{{
            DISPLAY_MESSAGES.MULTIPLE_CITIES_USER_DETAILS_ERROR
          }}</b-alert>
        </b-col>
      </b-form-row>
    </section>
    <section class="px-5 mt-4" v-else-if="validateUserToSelectedTenant">
      <b-form-row>
        <b-col>
          <b-alert show variant="danger">{{
            DISPLAY_MESSAGES.USER_NOT_BELONG_TO_TENANT
          }}</b-alert>
        </b-col>
      </b-form-row>
    </section>
    <div class="p-5" v-else-if="!userDetails">
      <h5>User details not available.</h5>
    </div>
    <div class="px-5" v-else>
      <section class="pt-5 pb-4 border-bottom-1">
        <h3 class="page-header page-header-highlight">Personal information</h3>
        <b-row class="mt-3 break-all">
          <b-col cols="12" sm="6" md="3" lg="3">
            Authenticated by:
            {{
              userDetails.portalUser.authenticatedBy
                ? userDetails.portalUser.authenticatedBy
                : "NA"
            }}
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            Email:
            <a :href="`mailto:${userDetails.portalUser.email}`">{{
              userDetails.portalUser.email
            }}</a>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            Tel:
            {{
              userDetails.portalUser.phoneNumber
                ? userDetails.portalUser.phoneNumber
                : "NA"
            }}
          </b-col>
        </b-row>
      </section>
      <section class="pt-4 pb-4 border-bottom-1">
        <h3 class="page-header page-header-highlight">Properties</h3>
        <h5 class="py-4 text-center" v-if="!userDetails.addresses.length">
          No properties available.
        </h5>
        <b-row class="mt-3" v-else>
          <b-col
            cols="12"
            sm="6"
            md="3"
            class="pb-3"
            v-for="(address, index) in userDetails.addresses.slice(
              0,
              propertyLimit
            )"
            :key="index"
          >
            <h6
              class="theme-font-medium"
              :class="{ 'pb-3': !address.isSelected && !address.addressLabel }"
            >
              {{
                address.isSelected
                  ? "Primary property"
                  : address.addressLabel
                  ? address.addressLabel
                  : ``
              }}
            </h6>
            <p class="mb-1">
              {{ address.streetNumber }} {{ address.streetName }}
              {{ address.unitNumber ? `, ${address.unitNumber}` : "" }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="userDetails.addresses.length > 4">
          <b-col class="text-center">
            <a
              href="javascript:void(0)"
              role="button"
              @click="
                propertyLimit =
                  propertyLimit > 4 ? 4 : userDetails.addresses.length
              "
              class="theme-font-bold"
              >{{ propertyLimit > 4 ? "View less" : "View more" }}</a
            >
          </b-col>
        </b-row>
      </section>
    </div>
    <ConfirmationModal
      :showModal="activateUserConfirmModal"
      :title="`CONFIRM`"
      :message="
        userDetails && userDetails.portalUser.isActive
          ? `DEACTIVATE_USER`
          : `ACTIVATE_USER`
      "
      @onConfirm="activateOrDeactivateUser"
      @closeConfirmModal="activateUserConfirmModal = false"
    />
    <ConfirmationModal
      :showModal="resetPasswordConfirmModal"
      :title="`CONFIRM`"
      :message="`RESET_PASSWORD_USER`"
      @onConfirm="resetPassword"
      @closeConfirmModal="resetPasswordConfirmModal = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import LoaderIcon from '../../assets/svg/loader.svg'
import { BIconArrowLeftShort } from 'bootstrap-vue'
import { DISPLAY_MESSAGES } from '../../utilities/constants'

export default {
  name: 'UserDetails',
  data () {
    return {
      userDetails: null,
      userDetailsLoading: false,
      propertyLimit: 4,
      activateUserConfirmModal: false,
      resetPasswordConfirmModal: false,
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      items: [
        {
          id: 1,
          name: 'Pools and swimmings',
          type: 'cell text',
          users: 2000,
          status: 'Web and mobile'
        },
        {
          id: 2,
          name: 'Arenas and Skating',
          type: 'cell text',
          users: 2500,
          status: 'Web and mobile'
        },
        {
          id: 3,
          name: 'Report a problem',
          type: 'cell text',
          users: 2800,
          status: 'Web'
        },
        {
          id: 4,
          name: 'News',
          type: 'cell text',
          users: 2000,
          status: 'Mobile'
        },
        {
          id: 5,
          name: 'Events',
          type: 'cell text',
          users: 1230,
          status: 'Active'
        },
        {
          id: 6,
          name: 'Whats near me',
          type: 'cell text',
          users: 2000,
          status: 'Inactive'
        },
        {
          id: 7,
          name: 'My utilities',
          type: 'cell text',
          users: 2000,
          status: 'Web and mobile'
        },
        {
          id: 8,
          name: 'Programs',
          type: 'cell text',
          users: 2000,
          status: 'Web'
        },
        {
          id: 9,
          name: 'Road closure',
          type: 'cell text',
          users: 5000,
          status: 'Web and mobile'
        },
        {
          id: 10,
          name: 'Snow removal',
          type: 'cell',
          users: 2000,
          status: 'Web'
        },
        {
          id: 11,
          name: 'My property tax',
          type: 'cell text',
          users: 2000,
          status: 'Web and mobile'
        },
        {
          id: 12,
          name: 'School closure',
          type: 'text',
          users: 2600,
          status: 'Active'
        }
      ],
      fields: [
        {
          key: 'name',
          label: 'Widget name',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'portal',
          label: 'Portal'
        },
        {
          key: 'email',
          label: 'Email'
        },
        { key: 'sms', label: 'sms' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 25],
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: ['name'],
      DISPLAY_MESSAGES: DISPLAY_MESSAGES
    }
  },
  components: {
    LoaderIcon,
    BIconArrowLeftShort,
    ConfirmationModal: () => import('../common/ConfirmationModal.vue')
  },
  computed: {
    restrictUsersToMultipleCity () {
      return (
        this.selectedTenant.length > 1 ||
        this.selectedTenant.some((tenant) => tenant.tenantID === 0)
      )
    },
    validateUserToSelectedTenant () {
      return (
        this.selectedTenant.length === 1 &&
        this.selectedTenant[0].tenantID !==
          this.userDetails.portalUser.tenantId
      )
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant
    })
  },
  created () {
    this.getUserDetails()
  },
  methods: {
    getUserDetails () {
      this.userDetailsLoading = true
      this.userDetails = null
      this.$store
        .dispatch('users/getUserDetails', this.$route.params.userId)
        .then((res) => {
          this.userDetailsLoading = false
          this.userDetails = res.data
        })
        .catch(() => {
          this.userDetailsLoading = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'USER_DETAILS_ERROR',
            type: 'danger'
          })
        })
    },
    activateOrDeactivateUser () {
      const postObj = [
        {
          userID: this.userDetails.portalUser.userId,
          isActive: !this.userDetails.portalUser.isActive
        }
      ]
      this.$store
        .dispatch('users/activateOrDeactivateUsers', postObj)
        .then((response) => {
          if (response.data) {
            this.activateUserConfirmModal = false
            this.$set(
              this.userDetails.portalUser,
              'isActive',
              !this.userDetails.portalUser.isActive
            )
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: this.userDetails.portalUser.isActive
                ? 'ACTIVATE_USER'
                : 'DEACTIVATE_USER',
              type: 'success'
            })
          } else {
            this.activateUserConfirmModal = false
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'FAILED',
              type: 'danger'
            })
          }
        })
        .catch(() => {
          this.activateUserConfirmModal = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger'
          })
        })
    },
    resetPassword () {
      const postObj = [
        {
          tenantId: this.userDetails.portalUser.tenantId,
          email: this.userDetails.portalUser.email
        }
      ]
      this.$store
        .dispatch('users/resetPassword', postObj)
        .then((response) => {
          this.resetPasswordConfirmModal = false
          if (response.data) {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'RESET_PASSWORD_SUCCESS',
              type: 'success'
            })
          } else {
            this.$store.commit('common/setCustomToastData', {
              message: false,
              key: 'RESET_PASSWORD_ERROR',
              type: 'danger'
            })
          }
        })
        .catch(() => {
          this.resetPasswordConfirmModal = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'RESET_PASSWORD_ERROR',
            type: 'danger'
          })
        })
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
